let language;

if (/.dk/.test(window.location.host)) {
  language = 'da';
} else if (/.se/.test(window.location.host)) {
  language = 'sv';
} else if (/.de/.test(window.location.host)) {
  language = 'de'
} else if (/.fr/.test(window.location.host)) {
  language = 'fr';
} else {
  language = 'en';
}

i18next
  .use(i18nextXHRBackend)
  .init({
    'load': 'languageOnly',
    'debug': true,
    'fallbackLng': 'en',
    'backend': {
      'loadPath': '/locales/' + language + '/{{ns}}.json'
    }
  }, (err, t) => {
    jqueryI18next.init(i18next, $);
    i18next.changeLanguage(language);
    $(document).localize();
  });
